<template>
  <div class="ui_slider_wrap">
    <VueSlider
      class="slider_control"
      :height="10"
      :dotStyle="{
        width: '20px',
        height: '20px',
        background: '#FFFFFF',
        border: 'solid 4px #258FFB',
        margin: '2px',
      }"
      :railStyle="{ background: '#D9D9D9', border: 'none' }"
      :processStyle="{ background: '#258FFB' }"
      v-bind="{ ...$attrs, ...$props }"
      @change="emitValue"
    />
    <!--<div class="labels">-->
      <!--<div>{{min}}</div>-->
      <!--<div>{{max}}</div>-->
    <!--</div>-->
  </div>
</template>

<script>
  import VueSlider from 'vue-slider-component';
  import 'vue-slider-component/theme/antd.css';

  export default {
    name: 'UiSlider',
    components: {
      VueSlider,
    },
    props: {
      value: {
        type: Number,
        required: true,
      },
      min: {
        type: Number
      },
      max: {
        type: Number
      },
    },
    methods: {
      emitValue(value) {
        console.log('slider value:', value);
        this.$emit('input', value)
        this.$emit('change', value)
      }
    }
  }
</script>

<style lang="scss">
  .ui_slider_wrap {

    .labels {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      color: #BFBFBF;
    }

    .vue-slider-mark-label {
      font-size: 16px;
      color: #BFBFBF;
    }
    .vue-slider-mark {
      width:1px !important;
    }

    .vue-slider-mark-step {
      /*border-radius: 0px;*/
      box-shadow: none !important;
    }
    /*.vue-slider-mark:first-child {*/
      /*.vue-slider-mark-step {*/
        /*border-radius: 50% 0px 0px 50%;*/
      /*}*/
    /*}*/
    /*.vue-slider-mark:last-child {*/
      /*.vue-slider-mark-step {*/
        /*border-radius: 0px 50% 50% 0px;*/
      /*}*/
    /*}*/

    .vue-slider-ltr .vue-slider-mark-label {
      transform: translate(-50%, -46px) !important;
    }

    .slider_control {
    }

    .vue-slider-dot {
      width: 24px !important;
      height: 24px !important;
    }

    .vue-slider-dot-handle {
      box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.2);
    }

    .vue-slider-mark:nth-child(2) .vue-slider-mark-label {
      display: none;
    }
  }
</style>
