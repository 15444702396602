<template>
  <a-textarea class="ui_text_area" v-bind="{ ...$attrs, ...$props }" v-on="listeners" />
</template>

<script>
  import fixListeners from '@/utils/fixListeners';

  export default {
    name: 'UiTextEdit',
    computed: {
      ...fixListeners()
    },
  }
</script>

<style scoped>
  .ui_text_area {
    font-size: 16px;
  }
</style>
