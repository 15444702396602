<template>
  <div class="flex-column">
    <filling-header icon="3" title="Student motivation" step="3/4" :done="isStepDone" />
    <ui-form
      :model="values"
      :rules="rules"
      :hideRequiredMark="true"
      @form="form = $event"
      @submit.prevent="handleSubmit"
    >
      <ui-form-item class="mb" label="Why Germany?" prop="whyGermany">
        <ui-text-edit
          v-model="values.whyGermany"
          :rows="4"
          placeholder="I want to live there because ..."
          :maxLength="500"
        />
      </ui-form-item>

      <ui-form-item
        class="mb"
        label="What qualities do you have that will make you deserve a Scholarship?"
        prop="yourQualities"
      >
        <ui-text-edit
          v-model="values.yourQualities"
          :rows="4"
          placeholder="My strong skills are ..."
          :maxLength="500"
        />
      </ui-form-item>

      <ui-form-item
        class="mb"
        label="How do you see yourself studying the German Language amidst of the pandemic?"
        prop="studyInPandemic"
      >
        <ui-text-edit
          v-model="values.studyInPandemic"
          :rows="4"
          placeholder="During this pandemic ..."
          :maxLength="500"
        />
      </ui-form-item>

      <ui-form-item
        label="From 0% to 100%, how serious are you in this application?"
        prop="howSerious"
      >
        <div class="howSerious-slider mb-32">
          <div class="confidenсe">{{$t('student-motivation.confiden_e')}}</div>
          <div class="percentage mb-32">{{ confidenсe }}</div>
          <ui-slider
            v-model="values.howSerious"
            :min="0"
            :max="150"
            :marks="[0,50,100,150]"
          ></ui-slider>
        </div>
      </ui-form-item>
    </ui-form>

    <div class="flex-grow"></div>

    <filling-footer @prev="prev" @next="handleSubmit"></filling-footer>
  </div>
</template>

<script>
import promiseValidateFields from '../../utils/promiseValidateFields';
import FillingHeader from '../../components/filling/FillingHeader';
import FillingFooter from '../../components/filling/FillingFooter';
import UiForm from '../../components/ui/UiForm';
import UiFormItem from '../../components/ui/UiFormItem';
import UiTextEdit from '../../components/ui/UiTextEdit';
import UiSlider from '../../components/ui/UiSlider';
import mapFields from '../../utils/mapFields';

export default {
  name: 'student-motivation',
  components: {
    UiSlider,
    UiTextEdit,
    UiFormItem,
    UiForm,
    FillingHeader,
    FillingFooter
  },
  data() {
    return {
      form: {},
      values: {
        whyGermany: '',
        yourQualities: '',
        studyInPandemic: '',
        howSerious: 0
      },
      rules: {
        whyGermany: [
          {
            required: true,
            message: 'Please, write a detailed answer'
          }
        ],
        yourQualities: [
          {
            required: true,
            message: 'Please, write a detailed answer'
          }
        ],
        studyInPandemic: [
          {
            required: true,
            message: 'Please, write a detailed answer'
          }
        ],
        howSerious: [
          {
            required: true,
            transform: (value) => (value ? `${value}` : ''),
            message: 'Please, write a detailed answer'
          }
        ],
      },
      isStepDone: false,
      studentDetailId: null,
    };
  },
  computed: {
    ...mapFields({
      studentId: 'auth.user.student.id'
    }),
    confidenсe() {
      const v = this.values.howSerious;
      if (v <= 25) return 'Not sure';
      if (v <= 50) return 'A little';
      if (v <= 75) return 'Have a doubt';
      if (v <= 99) return 'Pretty much';
      if (v <= 125) return 'Confident!';
      return 'Can’t Wait!';
    }
  },
  methods: {
    prev() {
      this.$router.push({ path: '/filling/professional-details' });
    },
    async handleSubmit() {
      try {
        await promiseValidateFields(this.form.validate);

        const values = { ...this.values };
        await this.$store.dispatch('students-details/patch', [
          this.studentDetailId,
          {
            motivation: values
          }
        ]);

        this.$router.push({ path: '/filling/personal-photo' });
      } catch (error) {
        this.$notify.parseFeathersErrors(error);
      }
    },
  },

  async mounted() {
    try {
      if (!this.studentId) {
        throw new Error('StudentId not found');
      }
      const { data } = await this.$store.dispatch('students-details/find', {
        query: {
          studentId: this.studentId
        }
      });
      this.studentDetailId = data[0].id;
      const values = data[0]?.motivation;
      console.log('Preload SM:', values);

      if (values?.whyGermany) {
        this.values = { ...this.values, ...values };
        this.isStepDone = true;
      }
    } catch (err) {
      this.$notify.parseFeathersErrors(err);
    }
  }
};
</script>

<style scoped>
.flex-column {
  display: flex;
  flex-direction: column;
}

.mb {
  margin-bottom: 16px !important;
}
.howSerious-slider {
  margin-top: 16px;
  padding: 0 10px;
}
.confidenсe {
  font-size: 14px;
  color: #7d7d7d;
  text-align: right;
}
.percentage {
  font-weight: 500;
  font-size: 18px;
  color: #383838;
  text-align: right;
}

.flex-grow {
  flex-grow: 1;
}
</style>
